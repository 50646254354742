<template xmlns="http://www.w3.org/1999/html">
  <div class="content" >
    <article
      class="row"
      style="margin-top: 10px; background: linear-gradient(#1d1d1d, black)"
    >

      <vue-whatsapp-widget
        phoneNumber="+524421867547"
        companyName="tulum.party"
        textReply="Typically replies within 10-15 mins (9am - 10pm)"
        companyLogo="https://imgix.cosmicjs.com/08f3cb30-e877-11ec-9177-7506dda64181-2022-06-09-23.37.07.jpg?h=50"
      />
      <section class="text-center col-lg-8 col-sm-12">


        <HeadImage :image="mainImage" align="text-center" title="Playa Del Carmen Clubs"
                   :color="primaryColor"
                   :clamp="true"
                   text-transform="uppercase"
                   caption="A Unique Adventure"></HeadImage>

        <p class="text-center" style="padding: 30px">




            Playa del Carmen is the cosmopolitan heart of the Riviera Maya, offering <strong>a diverse nightlife scene</strong> centered around 5th Avenue, where you can find everything from <strong>laid-back bars to high-energy nightclubs.</strong>

          <br>
          <br>

          Check out whats hot this <strong class="label label-info font-weight-700">{{
              getCurrentMonth
            }}</strong>
        </p>


        <section>

            <div style="min-height: 51px">

              <img
                v-for="(row, idx) in venues.slice(0,5)"
                v-bind:alt="row.title"
                v-lazy="row.metadata.main_image.imgix_url + '?fit=clamp&h=50&w=50'"
                height="50px"
                width="50px"
                style="border-radius: 0px"
                v-if="row.metadata.main_image"
              />

            <h5
              class="tulum-party-subtitle"
              style="
                text-transform: uppercase;
                font-size: 0.8rem;
                letter-spacing: 0.1rem;
                font-weight: bold;
              "
            >
              <br/>
               Want to discover what Playa has to offer?
            </h5>
            <h5 style="margin-top: -10px">
                Drop us a line
            </h5>
            <br/>
            <RsvpViaDialog></RsvpViaDialog>
            <br/>
            <small style="color: aquamarine">
              We operate from 9AM - 10PM / Answer within 15 mins</small
            >
            <br/>
            <small>NO EXTRA FEE</small>
          </div>


        </section>

        <section class="row" style="margin-top: 80px"
                 :style="{ background: `linear-gradient(black, ${this.primaryColor}, black, ${this.primaryColor})` }">



          <h4 class="section-header" style="font-size: 1.4rem;border-top: 2px solid cadetblue">Playa Top Festive Clubs</h4>
          <br>
          <br>
          <card
            class="card text-center col-sm-12 col-lg-12"
            v-for="(row, idx) in venues"
            :key="idx"
            style="box-shadow: #000000 10px 10px 2px; padding: 40px"
          >
            <template slot="header">

              <router-link :to="{ name: 'PDCClub', params: { slug: row.slug } }">
                <ResponsiveImage :image="row.metadata.main_image.imgix_url"
                                 class="card-animation-on-hover"></ResponsiveImage>

                <h4 class="tulum-party-title" style="margin-top: 20px;font-size: 1.7rem">
                  {{ row.title }}
                </h4>


                <p style="margin-top: -20px;font-size: 0.8rem" v-if="clubsConfig[row.slug]">{{ clubsConfig[row.slug]["caption"] }}</p>

                <hr class="brand-title-black" style="height: 1px"/>
              </router-link>
              <router-link
                :to="{ name: 'PDCClub', params: { slug: row.slug } }"
                class="badge-pill"
                style="
              background-color: #23d5ab;
              margin-bottom: 5px;
              color: black !important;
            "
                v-if="incomingClubParties[row.title]"
              >{{ incomingClubParties[row.title].length }} Upcoming Events
              </router-link>
              <br v-if="incomingClubParties[row.title]"/>
              <hr class="brand-title-black" style="height: 4px"/>
              <div
                v-html="row.metadata.description"
                class="md-small-hide"
                style="color: #fff !important; margin-bottom: 10px"
              ></div>
              <br/>
            </template>

            <template slot="default">
              <router-link
                :to="{ name: 'PDCClub', params: { slug: row.slug } }"
                class="btn font-weight-bold btn-primary"
                style="margin-top: 40px"
              >
                INFO & EVENTS
              </router-link>
            </template>
          </card>

        </section>
      </section>
      <aside class="card col-4 col-lg-4 d-none d-lg-inline">

        <SmartMenu :links="links" :logo="this.mainImage" ctaText="DROP US A LINE" top-section-name="Have Fun">
          <div class="row">
            <div class="col-sm-12 text-center">
              <p><strong>Feeling Lost?</strong></p>
              <p>
                We Are Your Single Contact for All Clubs & Events In The Riviera Maya.
              </p>
              <hr>
            </div>
          </div>
        </SmartMenu>

      </aside>

    </article>
  </div>
</template>

<script>
// import ClubFilter from "@/components/ClubFilter";
import clubsTableConfig from '../../common/pdcClubsTableConfig';
import HeadImage from "@/components/HeadImage/HeadImage";
import SmartMenu from "@/components/SmartMenu/SmartMenu";
import ResponsiveImage from "@/components/HeadImage/ResponsiveImage";
import clubsConfig from '../../common/pdcClubsConfig';

const RsvpViaDialog = () =>
  import(
    /* webpackChunkName: "RsvpDialogs" */ '../../components/Reservations/RsvpViaDialog'
    );
const VueWhatsappWidget = () =>
  import(
    /* webpackChunkName: "vue-whatsapp-widget" */ '../../components/WhatsAppWidget/Widget'
    );

export default {
  name: 'PDCClubs',
  data: () => {
    return {
      showPalms: false,
      primaryColor: '#587763',
      ClubTagsConfig: {tags: []},
      clubsConfig: [],
      mainImage:
      'https://imgix.cosmicjs.com/ab125620-979d-11ef-b5a0-93db72e2be98-pdc-by-night.jpg',
    };
  },
  metaInfo: () => ({
    title:
      "Playa Del Carmen Top Clubs: Where to Dance & Have Fun",
    link: [{rel: 'canonical', href: 'https://www.tulum.party/playa-del-carmen-clubs'}],
    meta: [
      {
        name: 'description',
        content:
          'Discover the best bars, restaurants & clubs in Playa del Carmen Mexico',
      },
      {
        name: 'keywords',
        content:
          'playa del carmen nightclubs, playa del carmen beach clubs, playa del carmen nightlife, playa del carmen dj events, playa del carmen party scene, playa del carmen music venues, playa del carmen dance clubs, playa del carmen bars, playa del carmen clubs calendar, playa del carmen events calendar, playa del carmen club reservations, playa del carmen vip tables, playa del carmen bottle service, playa del carmen bachelorette party, playa del carmen bachelor party, playa del carmen vacation activities, playa del carmen things to do at night',
      },
      {
        property: 'og:title',
        content: `Playa Del Carmen's Top Clubs To Dance & Have Fun`,
      },
      {property: 'og:type', content: 'website'},
      {property: 'og:locality', content: 'playa del carmen'},
      {property: 'og:country-name', content: 'Mexico'},
      {
        property: 'og:description',
        content:  'Discover the best bars, restaurants & clubs in Playa del Carmen Mexico',
      },
      {
        property: 'og:image',
        content:
          'https://imgix.cosmicjs.com/ab125620-979d-11ef-b5a0-93db72e2be98-pdc-by-night.jpg',
      },
    ],
  }),
  components: {
    ResponsiveImage,
    SmartMenu,
    HeadImage,
    clubsConfig,
    RsvpViaDialog,
    VueWhatsappWidget,
  },
  computed: {
    links: {
      get() {
        return (
          this.$store.state.links || []
        );
      },
    },
    incomingParties: {
      get() {
        return this.$store.state.incomingParties;
      },
    },
    incomingClubParties: {
      get() {
        return this.$store.state.incomingClubParties;
      },
    },
    venues: {
      get() {
        return this.$store.state.pdc_venues;
      },
    },
    getCurrentMonth: {
      get() {
        let d = new Date();
        return (
          [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ][d.getMonth()] +
          ' ' +
          d.getFullYear()
        );
      },
    },
    sortedVenues: {
      get() {
        return [...this.$store.state.pdc_venues].sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
      },
    },
  },
  created() {
    // alert( Intl.DateTimeFormat().resolvedOptions().timeZone);
    this.$store.dispatch('getPDCClubs', {start: 0, limit: 16});
    // this.$store.dispatch('getParties');
    this.$store.dispatch('getLinks', {slug: 'cancun-clubs'});

    this.ClubTagsConfig = clubsTableConfig;
    this.clubsConfig = clubsConfig;

    window.history.scrollRestoration = 'manual';
  },
  updated() {
    // this.ClubTagsConfig = clubsTagsConfig;
    setTimeout(() => (this.showPalms = true), 5000);
  },
  methods: {
    paginate() {
      this.showAll = true;
      this.$store.dispatch('getPDCClubs', {start: 12, limit: 20});
    },
  },
};
</script>
